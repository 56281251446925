
.main-wrapper {
  height: 80%;
  padding: 0 30px 0 10px;
  .title {
    text-align: center;
    padding-bottom: 20px;
    .titleData {
      line-height: 40px;
    }
  }
  .el-scrollbar {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
      height: 100%;
      overflow-x: hidden;
      .el-scrollbar__view {
        height: 100%;
      }
    }
  }
  .del {
    color: #ff3e6c;
    border: 1px solid #ff3e6c;
  }
  .footer-button {
    text-align: center;
    padding-top: 15px;
  }
 
  .explain {
    .el-scrollbar {
      // height: 100%;
      max-height: 300px;
      min-height: 20%;
      padding-bottom: 10px;
      ::v-deep .el-scrollbar__wrap {
        max-height: 300px;
        min-height: 80px;
        overflow-x: hidden;
        .el-scrollbar__view {
          max-height: 300px;
          min-height: 80px;
        }
      }
    }
    .foot-buttom {
      text-align: right;
      padding-top: 10px;
      ::v-deep .del:focus,
      .del:hover {
        background-color: #fef0f0;
      }
    }
  }
  .no-data-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .no-data-font {
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 450;
      color: #a8a8b8;
    }
  }

  .explain {
    // width: 1595px;
    background: #ffffff;
    border-radius: 2px;
    .explain-header {
      display: flex;
      align-items: center;
      height: 60px;
      background: #f0f3fc;
      box-sizing: border-box;
    }
    .item {
      display: flex;
      border: 2px solid #f7f8fc;
      .explain-body-item {
        display: flex;
        align-items: center;
        min-height: 64px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
